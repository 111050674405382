import './Video.css'
import { useRef, useEffect} from "react"

const Video = () => {
    const videoRef = useRef()
    useEffect(() => {
       console.log(videoRef)
       videoRef.current.play()
    }, [])
    

    return ( 
    <div className="VideoWrapper">
        <video ref={videoRef} controls="false" muted loop onClick={()=>{videoRef.current.muted = !videoRef.current.muted}}>
            <source src="https://firebasestorage.googleapis.com/v0/b/goonseshcom.appspot.com/o/videos%2FReeperbahn.mp4?alt=media&token=bf03fcbe-b757-44bc-9963-69b5e82e0717" type="video/mp4"/>
        </video>
    </div> 
    );
}
 
export default Video;