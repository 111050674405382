import '../Lander/Signup.css'
import {useAuth} from '../../contexts/AuthContext'
import { useUser } from '../../contexts/UserContext'
import { useRef, useState} from "react"
import {Link, useNavigate} from 'react-router-dom'
import { storage } from '../../firebase'
import ProfilePicture from './ProfilePicture'
import { ref, uploadBytes, getDownloadURL} from 'firebase/storage'
import {v4} from 'uuid'

const EditProfile = () => {
    const emailRef = useRef()
    const userRef = useRef()
    const buttonRef = useRef();
    const { currentUser} = useAuth()
    const { currentUserInfo, updateUser } = useUser()
    const [error, setError] = useState([])
    const [loading, setLoading] = useState(false)
    const [previewURL, setPreviewURL] = useState()
    const [uploadImage, setUploadImage] = useState()
    const navigate = useNavigate()
    
 

    async function handleSubmit(e) {
        e.preventDefault()
        try {
            setError([])
            var updateDict = {}
            if(userRef.current.value){
                if (userRef.current.value.length < 17){
                    updateDict["name"] = userRef.current.value
                }
                else {
                    return setError(["Username cannot be longer than 16 characters"])
                }
            }
            if (uploadImage != null) {
                const imageRef = ref(storage, `images/${uploadImage.name + v4()}`)
                const snapshot = await uploadBytes(imageRef, uploadImage)
                const url = await getDownloadURL(snapshot.ref)
                updateDict["profile_pic"] = url
            }
            setLoading(true)
            if (Object.keys(updateDict).length > 0) {
                await updateUser(updateDict)
            }
            navigate('/')
        } catch(err) {
            console.error(err.message)
            setError(["Failed to update Account"])
  
        }
        setLoading(false)
    }

    const previewImage = (e) =>{
        try{
            setUploadImage(e.target.files[0])
            const url = URL.createObjectURL(e.target.files[0])
            setPreviewURL(url)
        }catch(err) {
            console.error(err.message)
        }
    }

    return (
        <>
            <div className="wrapper">
                <form onSubmit={handleSubmit}>
                    <h1>Edit Profile</h1>
                    {!previewURL && <ProfilePicture url={currentUserInfo.profile_pic} onClick={()=>buttonRef.current.click()} editable/>}
                    {previewURL && <ProfilePicture previewURL={previewURL} onClick={()=>buttonRef.current.click()} editable/>}
                    <input ref={buttonRef} type="file" className='pickFile' onChange={previewImage} accept="image/png, image/gif, image/jpeg, image/webp"/>
                    {error && error.length > 0 && <div className='display-error'>
                        {error.map((item) => (<div>{item}</div>))}
                        </div>}
                    <div className='input-wrapper'>
                        <div className='description'>Email:</div>
                        <div className="input-box" style={{margin:0}}>
                            <input type="text" disabled placeholder={currentUser.email} ref={emailRef} required/>
                        </div>
                    </div>
                    <div className='input-wrapper'>
                        <div className='description'>Username:</div>
                        <div className="input-box" style={{margin:0}}>
                            <input type="text" placeholder={currentUserInfo.name} ref={userRef}/>
                        </div>
                    </div>
                    <button disabled={loading} type=" " className="btn">Update Profile</button>
                    <div className='login-link'>
                        <p><Link to='/m'>Abort changes</Link></p>
                    </div>
                </form>
            </div>
        </>
    );
}
 
export default EditProfile;