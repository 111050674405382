import {useAuth} from '../../contexts/AuthContext'
import { useUser } from '../../contexts/UserContext';
import { Outlet, Link } from "react-router-dom";
import ProfilePicture from "./ProfilePicture";
import {useNavigate} from 'react-router-dom'
import './Navbar.css'
import { useState, Component} from 'react';
import { FaRegBell } from "react-icons/fa";
import { FaMagnifyingGlass } from "react-icons/fa6";

class Bell extends Component {
    render() {
      return <FaRegBell style={{fontSize:"1.5rem"}} />
    }
}
class MagnifyingGlass extends Component {
    render() {
      return <FaMagnifyingGlass className="magnifyingGlass"/>
    }
}

const Members = () => {
    const { logout } = useAuth()
    const { currentUserInfo } = useUser()
    const navigate = useNavigate()
    const [openProfile, setOpenProfile] = useState(false)
    const [loading, setLoading] = useState(false)
    async function handleLogout(){
        try {
            setLoading(true)
            await logout()
        } catch(err) {
            console.error(err.message)
        }
        setLoading(false)
    }

    return ( 
        <>
        <nav className="nav">
        <Link to="/" className="Title">Goonsesh</Link>
        <div className="input-box">
            <input type="Text" placeholder="Search" required/>
        </div>
        <ul>
        <MagnifyingGlass />
        <li>
        <Link to="/m/"><Bell/></Link>
        </li>
        <ProfilePicture style={{marginTop:"0.2rem",height:"2.7rem", width: "2.7rem", border:"1px solid rgba(255,255,255,0.4"}} url={currentUserInfo.profile_pic} onClick={()=>setOpenProfile((prev)=>!prev)}/>
        {openProfile &&
        <div className='dropdown'>
            <ul>
                <li style={{padding:"0 3rem", fontSize:"1rem"}}>Hi {currentUserInfo.name}</li>
                <li><button className='invisblebutton' onClick={()=>navigate("/m/edit-profile")}>Edit Profile</button></li>
                <li><button className='invisblebutton'>Upload Video</button></li>
                <li><button className='invisblebutton'>Settings</button></li>
                <li style={{borderTop:"1px solid rgba(255,255,255,0.2)"}}><button className='invisblebutton' disabled={loading} onClick={handleLogout}>Log Out</button></li>
            </ul>
        </div>}
        </ul>
        </nav>
        <Outlet/>
        </>
     );
}
 
export default Members;