import './SideNav.css' 
import { Component} from 'react';
import { GiCaveEntrance } from "react-icons/gi";
import { FaUserFriends } from "react-icons/fa";
import { FaPepperHot } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { CiStreamOn } from "react-icons/ci";
import { BsCollectionPlayFill } from "react-icons/bs";
import { FaFilter } from "react-icons/fa6";

class Cave extends Component {
    render() {
      return <GiCaveEntrance className="buttonIcon" />
    }
}
class Friends extends Component {
    render() {
      return <FaUserFriends className="buttonIcon" />
    }
}
class Flame extends Component {
    render() {
      return <FaPepperHot className="buttonIcon" />
    }
}
class Message extends Component {
    render() {
      return <IoMdMail className="buttonIcon" />
    }
}
class Live extends Component {
    render() {
      return <CiStreamOn className="buttonIcon" />
    }
}
class Collection extends Component {
    render() {
      return <BsCollectionPlayFill className="buttonIcon" />
    }
}
class Filter extends Component {
    render() {
      return <FaFilter className="buttonIcon" />
    }
}



const SideNav = () => {
    return ( 
        <nav className='SideNav'>
            <ul>
                <li><button className='sidebutton'><Cave></Cave> <span className="buttonText"> Gooncave</span></button></li>
                <li><button className='sidebutton'><Filter></Filter><span className="buttonText"> Filter</span></button></li>
                <li><button className='sidebutton'><Friends></Friends><span className="buttonText"> Gooners</span></button></li>
                <li><button className='sidebutton'><Flame></Flame><span className="buttonText"> Explore</span></button></li>
                <li><button className='sidebutton'><Message></Message><span className="buttonText"> Messages</span></button></li>
                <li><button className='sidebutton'><Collection></Collection><span className="buttonText"> Collection</span></button></li>
                <li><button className='sidebutton'><Live></Live><span className="buttonText"> Live</span></button></li>
            </ul>
        </nav>
    )
}
 
export default SideNav;