
import SideNav from "./SideNav";
import Video from "./Video";
const Dashboard = () => {
    return ( 
    <div style={{display:"flex"}}>
        <SideNav/>
        <Video/>
    </div> 
    );
}
 
export default Dashboard;