import './Signup.css'
import {useAuth} from '../../contexts/AuthContext'
import { useRef, useState} from "react"
import {Link} from 'react-router-dom'

const ResetPassword = () => {
    const emailRef = useRef()
    const { resetPassword} = useAuth()
    const [error, setError] = useState([])
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    

    async function handleSubmit(e) {
        e.preventDefault() 
        try {
            setError([])
            setMessage("")
            setLoading(true)
            await resetPassword(emailRef.current.value)
            setMessage("Reset Mail sent!")
        } catch(err) {
            console.error(err.message)
            if ((err.message.includes("invalid-email"))){
                setError(["invalid email"])
            } else {
                setError(["Failed to reset"])
            }
        }
        setLoading(false)
    }

    return (
        <>
            <div className="wrapper">
                <form onSubmit={handleSubmit}>
                    <h1>Reset Password</h1>
                    {error && error.length > 0 && <div className='display-error'>
                        {error.map((item) => (<div>{item}</div>))}</div>}
                    <div className="input-box">
                        <input type="text" placeholder="Email" ref={emailRef} required/>
                    </div>
                    {message && <div className='display-message'>{message}</div>}
                    {!message && <button disabled={loading} type=" " className="btn">Reset Password</button>}

                    <div className='login-link'>
                        Back to <Link to='/login'>Log In</Link>
                    </div>
                    <div className='login-link'>
                        Need an Account? <Link to='/signup'>Sign Up</Link>
                    </div>
            </form>

        </div>
        </>
    );
}
 
export default ResetPassword;