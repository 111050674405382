import React, { useContext, useEffect, useState } from "react";
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, signOut} from 'firebase/auth'
import {auth} from '../firebase'

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) =>{
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  function signup (email, password){
    return createUserWithEmailAndPassword(auth, email, password)
  }

  function login (email, password){
    return signInWithEmailAndPassword(auth, email, password)
  }

  function logout (){
    return signOut(auth)
  }

  function resetPassword(email){
    return sendPasswordResetEmail(auth, email)
  }


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })
    return unsubscribe
  }, [])

  const value = {
    currentUser,
    signup,
    login,
    logout,
    resetPassword
  }
  return (
    <AuthContext.Provider value={value}>
      {loading && <div style={{
        color: "white",
        marginTop: "10vh",
        fontFamily:"Afacad Flux",
        fontWeight:"600",
        fontSize:"20px"
      }}>Loading...</div>}
      {!loading && children}
    </AuthContext.Provider>
  )
}