import './App.css';
import { AuthProvider } from '../contexts/AuthContext';
import { UserProvider } from '../contexts/UserContext';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Members from './Members/Members'
import Lander from './Lander/Lander'
import PrivateRoute from "./Routes/PrivateRoute"
import PublicRoute from "./Routes/PublicRoute"
import EditProfile from "./Members/EditProfile"
import Dashboard from './Members/Dashboard';
import Signup from './Lander/Signup'
import Login from './Lander/Login'
import ResetPassword from './Lander/ResetPassword'
import LandingPage from './Lander/LandingPage';

function App() {
  return (
    
      <div className="App">
       
        <BrowserRouter>
          <AuthProvider>
              <Routes>
                  <Route path= '/' element={<PublicRoute><Lander/></PublicRoute>}> 
                    <Route index element={<LandingPage/>}/>
                    <Route path='/signup' element={<Signup/>} />
                    <Route path='/login' element={<Login/>} />
                    <Route path='/reset-password' element={<ResetPassword/>} />
                  </Route> 
                  <Route path='/m' element={<UserProvider><PrivateRoute><Members/></PrivateRoute></UserProvider>}>
                    <Route index element={<Dashboard/>}/>
                    <Route path='/m/edit-profile' element={<EditProfile/>} />
                  </Route>       
                </Routes>   
          </AuthProvider>
        </BrowserRouter>
      </div>
    
  );

}

export default App;
