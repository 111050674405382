import './Signup.css'
import {useNavigate} from 'react-router-dom'

const LandingPage = () => {
    const navigate = useNavigate()
    return (
        <div >
        <button className="singlebtn" style={{marginTop:"10vh", fontSize:"2rem"}}onClick={()=>navigate('/login')} >Login</button>
        </div>
     );
}
 
export default LandingPage;