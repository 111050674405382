import './Signup.css'
import {useAuth} from '../../contexts/AuthContext'
import { useRef, useState} from "react"
import {Link, useNavigate} from 'react-router-dom'

const Login = () => {
    const emailRef = useRef()
    const passwordRef = useRef()
    const { login } = useAuth()
    const [error, setError] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    async function handleSubmit(e) {
        e.preventDefault() 
        try {
            setError([])
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
            navigate('/m')
        } catch(err) {
            console.error(err.message)
            if (err.message.includes("invalid-credential")){
                setError(["Email or Password not correct"])
            } else if (err.message.includes("invalid-email")){
                setError(["invalid email"])
            } else {
                setError(["Failed to log in"])
            }
        }
        setLoading(false)
    }

    return (
        <>
            <div className="wrapper">
                <form onSubmit={handleSubmit}>
                    <h1>Log In</h1>
                    {error && error.length > 0&& <div className='display-error'>
                        {error.map((item) => (<div>{item}</div>))}
                        </div>}
                    <div className="input-box">
                        <input type="text" placeholder="Email" ref={emailRef} required/>
                    </div>
                    <div className="input-box">
                        <input type="password" placeholder="Password" ref={passwordRef} required/>
                    </div>
                    <button disabled={loading} type=" " className="btn">Log In</button>
                    <div className='login-link'>
                        Need an Account? <Link to='/signup'>Sign Up</Link>
                    </div>
                    <div className='forgot-link'>
                        <Link to='/reset-password'>Forgot Password?</Link>
                    </div>
            </form>
            </div>
        </>
    );
}
 
export default Login;