import { createContext, useContext, useEffect, useState  } from "react";
import {useAuth} from '../contexts/AuthContext'
import { db } from '../firebase'
import { collection, doc, getDoc, updateDoc} from 'firebase/firestore'

const UserContext = createContext()

export function useUser() {
  return useContext(UserContext)
}

export const UserProvider = ({children }) =>{
    const { currentUser } = useAuth()
    const [currentUserInfo, setCurrentUserInfo] = useState()
    const [loading, setLoading] = useState(true)

    async function getCurrentUserInfo() {
        if(currentUser){
          const u = await getDoc(doc(collection(db, 'users'), currentUser.uid))
          setCurrentUserInfo(u.data())
        } else {
          setCurrentUserInfo(null)
        }
    }
    
    async function updateUser(updateDict) {
        try{
          await updateDoc(doc(collection(db, 'users'), currentUser.uid), updateDict)
          getCurrentUserInfo()
        } catch(err){
          console.error(err)
        }
    }


    async function initPage(){
        await getCurrentUserInfo(currentUser)
        setLoading(false)
    }

    useEffect(() => {
        initPage()
    }, [currentUser])

    const value = {
        currentUserInfo,
        updateUser
    }
    return (
        <UserContext.Provider value={value}>
            {loading && <div style={{
            color: "red",
            marginTop: "10vh",
            fontFamily:"Afacad Flux",
            fontWeight:"600",
            fontSize:"20px"
            }}>Loading...</div>}
            {!loading && children}
        </UserContext.Provider>
      )

}