import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyAbE-jKWt8Nu16mGJRL3K1ZEcUFRHHBmwI",
  authDomain: "goonseshcom.firebaseapp.com",
  projectId: "goonseshcom",
  storageBucket: "goonseshcom.appspot.com",
  messagingSenderId: "413699317120",
  appId: "1:413699317120:web:dbd58d3da8d43a99347565",
  measurementId: "G-DD3YYZPYD5"
}


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
