import './Signup.css'
import {useAuth} from '../../contexts/AuthContext'
import { useRef, useState} from "react"
import {Link, useNavigate} from 'react-router-dom'
import { db } from '../../firebase'
import { collection, setDoc, doc} from 'firebase/firestore'

const Signup = () => {
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { signup } = useAuth()
    const [error, setError] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    

    async function setNewUser(id, email) {
        const username = email.split('@')[0]
        const user = {
            name: username, 
            profile_pic: 'https://firebasestorage.googleapis.com/v0/b/goonseshcom.appspot.com/o/images%2FBreezeicons-actions-22-im-user.svg.pngdb27ac95-a39e-47ec-860a-d78e1ae22466?alt=media&token=9f61d089-c4eb-4a74-801c-f903086271ba'
        };
        const myCollection = collection(db, 'users');
        const myDocRef = doc(myCollection, id);
        return setDoc(myDocRef, user);
    }

    async function handleSubmit(e) {
        e.preventDefault() 

        if (passwordRef.current.value !== passwordConfirmRef.current.value){
            return setError(['Passwords do not match'])
        }
        try {
            setError([])
            setLoading(true)
            const cred = await signup(emailRef.current.value, passwordRef.current.value)
            await setNewUser(cred.user.uid, cred.user.email)
            navigate('/m/edit-profile')
        } catch(err) {
            console.error(err.message)
            if (err.message.includes("email-already-in-use")){
                setError(["Email already in use"])
            } else if (err.message.includes("invalid-email")){
                setError(["invalid email"])
            } else if (err.message.includes("password-does-not-meet-requirements")){  
                let message = err.message.split('[')[1]
                message = message.split(']')[0]
                message = message.split(', ')
                setError(message)
            } else {
                setError(["Failed to create an account"])
            }
        }
        setLoading(false)
    }

    return (
        <>
            <div className="wrapper">
                <form onSubmit={handleSubmit}>
                    <h1>Sign Up</h1>
                    {error && error.length > 0&& <div className='display-error'>
                        {error.map((item) => (<div>{item}</div>))}
                        </div>}
                    <div className="input-box">
                        <input type="text" placeholder="Email" ref={emailRef} required/>
                    </div>
                    <div className="input-box">
                        <input type="password" placeholder="Password" ref={passwordRef} required/>
                    </div>
                    <div className="input-box">
                        <input type="password" placeholder="Confirm Password" ref={passwordConfirmRef} required/>
                    </div>
                    <button disabled={loading} type=" " className="btn">Sign Up</button>
                    <div className='login-link'>
                        <p>Already have an acoount? <Link to='/login'>Log In</Link></p>
                    </div>
            </form>

        </div>
        </>
    );
}
 
export default Signup;