import { Outlet } from "react-router-dom";
import "./Lander.css"
const Lander = () => {
    return ( 
        <div>
        <div className= "board">
            <span className="GlowingTitle">Goonsesh.com</span>
        </div>
        <span className = "GlowingText">Coomming Soon</span>
        <Outlet/>
        </div>
     );
}
 
export default Lander;