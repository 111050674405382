import './ProfilePicture.css'
import { useEffect, useState} from "react"


const ProfilePicture = (props) => {
    const [image, setImage] = useState()
    useEffect(() => {
        if(props.url) {
            setImage(props.url)
        }else if (props.previewURL){
            setImage(props.previewURL)
        }
    }, [props])
    
    return ( 
        <>
        <div className="round-image" style={props.style} onClick={()=>props.onClick()}>
        {image && <img draggable="false" src={image} className='image' alt='Profile'/>}
        {props.editable && <div className="overlay">
            <div className="text">Edit</div>
        </div>}
        </div>
        </>
     );
}
 
export default ProfilePicture;